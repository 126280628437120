<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center mt-5">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <div class="row">
            <div class="col-md-6">
                <mat-card class="bg-white mat-elevation-z3">
                    <div class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="back()"
                         style="cursor: pointer" (keydown.backspace)="back()" role="button" aria-hidden="true">
                        <mat-icon aria-hidden="false" matTooltip="Close" matTooltipPosition="below">close</mat-icon>
                    </div>
                    <div class="col-9 header">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">person</mat-icon>
                                User Profile Info
                            </mat-card-title>
                            <mat-card-subtitle>Information about the user.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <mat-card-content>
                        <div class="pt-4">
                            <h4><i class="fa-regular fa-file-lines text-primary-color"></i> Data:</h4>
                            <ul class="no-bullets">
                                <li><h5>name: <b class="text-primary-color">{{ user.name }}</b></h5></li>
                                <li><h5>company name: <b class="text-primary-color">{{ user.company_name }}</b></h5>
                                </li>
                                <li><h5>e-mail: <b class="text-primary-color">{{ user.email }}</b></h5></li>
                                <li><h5>regulations: <b class="text-primary-color">{{ user.regulations }}</b></h5></li>
                                <li><h5>confirmed: <b class="text-primary-color">{{ user.confirmed }}</b></h5></li>
                                <li><h5>active: <b class="text-primary-color">{{ user.status }}</b></h5></li>
                            </ul>
                            <h4>
                                <mat-icon color="primary">manage_accounts</mat-icon>
                                Settings:
                            </h4>
                            <ul class="no-bullets">
                                <ng-container *ngIf="!editTypeMode else editType">
                                    <li><h5>type: <b class="text-primary-color">{{ user.type }}</b></h5></li>
                                    <div *ngIf="user.type !== 'admin'" class="d-flex justify-content-end">
                                        <button class="mx-4" mat-stroked-button (click)="switchEditTypeMode()">
                                            <i class="fa-solid fa-pen-to-square"></i> Edit
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-template #editType>
                                    <div class="pt-2">
                                        <mat-form-field class="three-quarters-width" appearance="outline">
                                            <mat-label>Change type:</mat-label>
                                            <mat-select [(value)]="userType">
                                                <mat-option [value]="'user'">user</mat-option>
                                                <mat-option [value]="'member'">member</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <app-save-button
                                            [invalid]="false"
                                            (closeForm)="switchEditTypeMode()"
                                            (save)="updateUserType()"
                                            [addMode]="false">
                                        </app-save-button>
                                    </div>
                                </ng-template>
                            </ul>
                        </div>
                    </mat-card-content>
                    <mat-progress-bar mode="indeterminate" *ngIf="isUpdating" color="primary"></mat-progress-bar>
                </mat-card>
            </div>
        </div>
        <div class="col-md-6 text-center py-5">
            <button mat-stroked-button (click)="back()">
                <i class="fa-solid fa-rotate-left"></i> Close
            </button>
        </div>
    </ng-template>
</div>
