<div class="py-2 px-2 py-md-5 px-md-5">
    <button class="mx-2" mat-stroked-button routerLink="/secure/service-orders">
        <i class="fa-regular fa-rectangle-list"></i> ServiceOrders
    </button>
    <button *ngIf="authService.user.type !== 'user'" class="mx-2" mat-stroked-button routerLink="/secure/intercom-codes">
        <mat-icon>keyboard</mat-icon> IntercomCodes
    </button>
    <button *ngIf="authService.user.type !== 'user'" class="mx-2" mat-stroked-button routerLink="/secure/working-times">
        <i class="fa-solid fa-clock-rotate-left"></i> WorkingTimes
    </button>
    <button *ngIf="authService.user.type === 'admin'" class="mx-2" mat-stroked-button routerLink="/secure/users">
        <i class="fa-solid fa-user-group"></i> Users
    </button>
    <button class="mx-2" mat-stroked-button routerLink="/secure/profile">
        <i class="fa-solid fa-user"></i> Profile
    </button>
</div>
