<mat-card class="bg-white mat-elevation-z3">
    <mat-card-header>
        <mat-card-title>
            <mat-icon class="mx-1" color="primary"> {{ isArchive ? 'checklist' : 'format_list_bulleted<' }}</mat-icon>
            Service Orders {{ isArchive ? 'Archive' : 'Current' }}
        </mat-card-title>
        <mat-card-subtitle>
            Check service orders list.
        </mat-card-subtitle>
        <button *ngIf="isMessage" mat-mini-fab color="warn" class="mx-2"
                matTooltip="Unread message" matTooltipPosition="right">
            <mat-icon>mail</mat-icon>
        </button>
        <div class="mx-3" *ngIf="authService.user.type === 'user'">
            <button mat-fab color="warn" matTooltip="Add new service order" routerLink="/secure/service-order-add"
                    matTooltipPosition="right" aria-label="Add new service order">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pt-4 pb-3">
            <div class="text-center text-md-start pb-2">
                <mat-form-field appearance="outline" style="width: 75%">
                    <mat-label>Street ...</mat-label>
                    <input type="text" matInput placeholder="Street ..."
                           (keyup)="setSearchFilter()" [(ngModel)]="searchFilter">
                    <mat-icon matSuffix color="primary">search</mat-icon>
                </mat-form-field>
            </div>
            <mat-accordion>
                <mat-expansion-panel class="bg-white" (opened)="cleanFilter()" (closed)="cleanFilter()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa-solid fa-filter text-primary-color mx-2"></i> Filter
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="pb-md-2">
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Start date</mat-label>
                            <input matInput [matDatepicker]="startPicker" [formControl]="date">
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <mat-datepicker #startPicker (closed)="setSearchFromDate()"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="py-2 mx-1 mx-xl-3" appearance="outline" color="primary">
                            <mat-label>End date</mat-label>
                            <input matInput [matDatepicker]="endPicker" [formControl]="date">
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                            <mat-datepicker #endPicker (closed)="setSearchToDate()"></mat-datepicker>
                        </mat-form-field>
                        <button mat-stroked-button color="primary" (click)="searchByDate()"
                                [disabled]="dateFrom === '' || dateTo === ''">
                            <mat-icon fontIcon="search"></mat-icon>
                            search
                        </button>
                    </div>
                    <div class="pt-4 pt-md-2">
                        <mat-form-field *ngIf="!isArchive" appearance="outline" color="primary">
                            <mat-label>Status</mat-label>
                            <mat-select (selectionChange)="setStatusFilter($event)"
                                        [(ngModel)]="statusFilter">
                                <mat-option [value]="''">all</mat-option>
                                <mat-option [value]="'created'">created</mat-option>
                                <mat-option [value]="'in_progress'">in_progress</mat-option>
                                <mat-option [value]="'not_finished'">not_finished</mat-option>
                                <mat-option [value]="'ready'">ready</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="py-2 mx-1" [ngClass]="{'mx-xl-3': !isArchive}" appearance="outline"
                                        color="primary">
                            <mat-label>Type</mat-label>
                            <mat-select (selectionChange)="setTypeFilter($event)"
                                        [(ngModel)]="typeFilter">
                                <mat-option [value]="''">all</mat-option>
                                <mat-option [value]="'gas'">gas</mat-option>
                                <mat-option [value]="'water'">water</mat-option>
                                <mat-option [value]="'sewers'">sewers</mat-option>
                                <mat-option [value]="'heating'">heating</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button [ngClass]="{'mx-xl-3': isArchive}" mat-stroked-button color="primary" (click)="cleanFilter()">
                            <mat-icon fontIcon="cleaning_services"></mat-icon>
                            clear
                        </button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div>
            <span>columns:</span>
            <mat-checkbox [(ngModel)]="numberColumn">number</mat-checkbox>
            <mat-checkbox [(ngModel)]="dateColumn">date</mat-checkbox>
            <mat-checkbox [(ngModel)]="typeColumn">type</mat-checkbox>
            <mat-checkbox [(ngModel)]="addressColumn">address</mat-checkbox>
            <mat-checkbox [(ngModel)]="statusColumn">status</mat-checkbox>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="orders$" matSort (matSortChange)="sortData($event)" matSortActive="number"
                   matSortDisableClear matSortDirection="desc">

                <!-- number column -->
                <ng-container matColumnDef="number">
                    <th [hidden]="!numberColumn" mat-header-cell *matHeaderCellDef mat-sort-header="number">number</th>
                    <td [hidden]="!numberColumn" mat-cell *matCellDef="let order">{{ order.id }}
                        <ng-container *ngIf="order.emergency">
                            <span class="text-danger mx-1" style="font-size: 16px"><i
                                class="fa-solid fa-truck-medical"></i></span>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- type column -->
                <ng-container matColumnDef="type">
                    <th [hidden]="!typeColumn" mat-header-cell *matHeaderCellDef mat-sort-header="type">type</th>
                    <td [hidden]="!typeColumn" mat-cell *matCellDef="let order">{{ order.type }}</td>
                </ng-container>

                <!-- date column -->
                <ng-container matColumnDef="date">
                    <th [hidden]="!dateColumn" mat-header-cell *matHeaderCellDef mat-sort-header="date">date</th>
                    <td [hidden]="!dateColumn" mat-cell *matCellDef="let order">{{ order.date | date:'dd/MM/y' }}</td>
                </ng-container>

                <!-- address column -->
                <ng-container matColumnDef="street">
                    <th [hidden]="!addressColumn" mat-header-cell *matHeaderCellDef mat-sort-header="street">address
                    </th>
                    <td [hidden]="!addressColumn" mat-cell *matCellDef="let order">
                        <ng-container *ngFor="let address of order.addresses">
                            <span>{{ address.street_name }} {{ address.street_number }}{{ address.apartment_number ? '/' : ' ' }}{{ address.apartment_number }} </span>
                            <br/>
                            <span>{{ address.zip_code }}  {{ address.city_name }} </span>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- status column -->
                <ng-container matColumnDef="status">
                    <th [hidden]="!statusColumn" mat-header-cell *matHeaderCellDef mat-sort-header="status">status</th>
                    <td [hidden]="!statusColumn" mat-cell *matCellDef="let order">
                        <app-service-order-status-color [status]="order.status"></app-service-order-status-color>
                    </td>
                </ng-container>

                <!-- show column -->
                <ng-container matColumnDef="show">
                    <th class="table-cell-width" mat-header-cell *matHeaderCellDef>show</th>
                    <td mat-cell *matCellDef="let order">
                        <div class="d-flex">
                            <button mat-mini-fab color="indigo" matTooltip="Show"
                                    (click)="showOrder(order.id)"
                                    matTooltipPosition="left" aria-label="Show">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                            <button *ngIf="checkMessage(order.messages)" class="mx-2" mat-mini-fab color="warn"
                                    matTooltip="Message"
                                    (click)="showOrder(order.id)"
                                    matTooltipPosition="right" aria-label="Message">
                                <mat-icon>mail</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="bg-white" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
        <ng-container *ngIf="!orders$.data.length">
            <div class="text-center py-5">
                <p>No service orders found.</p>
            </div>
        </ng-container>
        <div class="mt-3" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
        </div>
    </mat-card-content>
</mat-card>
